.title{
    font-size: 1.3rem;
}

.card_event-box{
    padding: 0rem 0.5rem;
    max-width: 500px;
    margin: 0px auto;
}


.card_event{
    display: grid;
    padding: 2rem 1rem;
    grid-template-columns: 1fr 0.3fr;
    gap:1.5rem;
    margin: 4rem 0em;
    border-radius: 15px;
}

.playLink{
    text-align: center;
    font-weight: bold;
    color: var(--white);
    align-self: center;
    text-decoration: none;
    padding: 2em 0em;
    border-radius: 0px;
    background-color: var(--light2);
    box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.2);
    transition: border-radius 0.2s linear, background-color 0.2s linear;
}

.playLink:hover{
    background-color: var(--normal);
    border-radius: 10px;
}

/* .card_event img{
    align-self: center;
    justify-self: center;
    object-fit: cover;
    border-radius: 10px;
} */

.event_text-box p:first-child{
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 0.2em;
}

.event_text-box p:first-child::first-letter{
    text-transform: uppercase;
}

.event_text-box p:nth-child(2){
    font-size: 0.8rem;
    margin: 0px;
    overflow: hidden;
}

.event_text-box a{
    display: inline-block;
    text-decoration: none;
    color: var(--dark);
    font-weight: bold;
    margin-top: 1rem;
}


/* .card_event img{
    width: 100px;
    height: 100%;
} */


.card_event-box .card_event:nth-child(odd){
    background-color: var(--light);
    box-shadow: inset -2px 5px 10px rgba(75, 114, 112, 0.542)
}

.card_event-box .card_event:nth-child(even){
    grid-template-columns: 0.3fr 1fr;
    background-color: var(--white);
    box-shadow: -2px 5px 10px rgba(69, 69, 69, 0.293)
}

.card_event-box .card_event:nth-child(even) div a{
    width: 100%;
    text-align: right;    
    padding-right: 0.5rem;
}

.no_event-box{
    font-size: 1rem;
    text-align: center;
    background-color: var(--dark);
    padding: 1em 0.5em;
    border-radius: 15px;
    margin: 3em 0em;
}
.no_event-box > p{
    color: var(--white);
}