.code-box{
    margin: 2rem auto;
    padding: 0.8rem;
    max-width: 400px;
}

.code-box >  p{
    border: 1px solid var(--dark2);
    height: 100%;
    padding: 0.5rem;
    border-radius: 5px 5px 0px 0px;
    color: var(--dark2);
    font-weight: bold;
    text-align: center;
    margin: 1em 0px 0em;
    background-color: var(--light);
    width: 100%;
}

.formCode{
    border: 1px solid var(--light);
    border-radius: 10px;
    margin: 0px;
}

.btn-code{
    display: block;
    width: 100%;
    margin: 0px auto;
    border-radius: 0px 0px 5px 5px;
    border-width: 1px;
}