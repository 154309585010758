.title{
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    text-align: center;
}

.btn-box{
    max-width: 300px;
    margin: 0px auto;
    text-align: right;
}

.resalt-box{
    text-align: center;
    width: 500px;
    padding: 0.3rem 0.2rem;
    background-color: var(--light);
    margin: 0px auto;
    border-radius: 10px;
}