footer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--dark2);
    justify-items: center;
    padding: 2em 1em;
    gap: 1.5em;
}

footer > div{
    font-size: 1rem;
    width: 100%;
    max-width: 400px;
}

.title{
    font-size: 1.1em;
    font-weight: bold;
    color: var(--white);
    margin:0px;
}

.list{
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.list a{ text-decoration: none; display: inline-block;color: var(--white);}

.nav_social .link_logo:first-child{
    margin-left: 0px
}
.link_logo{
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 0.8em;
    background: url("./../../source/src_icon/instagram.png") center center;
    background-size: contain;
    text-indent: -9999px;
    overflow: hidden;
}

.logo_tiktok{
    background: url("./../../source/src_icon/tiktok.png") center center;
    background-size: contain;
}

@media (max-width: 600px) {
    .footer{
        grid-template-columns: 1fr;
    }
}