.box{
    padding: 0px 4px;
}
.form{
    border: 1px solid var(--light);
    align-items: baseline;
    padding: 1em 0.5em;
    max-width: 520px;
    margin: 0px;
    margin: 1em auto;
}

.form button{
    display: block;
    margin-left: auto;
}

.form > div{
    max-width: initial;
    margin: 0px;
}

