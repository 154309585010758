.box{
    overflow-x: auto;
    margin: 2em 0.5em;
}
@media screen and (min-width:600px) {
    .box{
        margin: 2em auto;
    }
}

.box-wheel{
    justify-items: center;
    background-color: var(--wheel_bg);
    padding: 1.5em 0.5rem 3em;
}

.wheel-title{
    margin: 0em;
    color: var(--wheel_text);
}

.box-wheel > button{
    display: block;
    margin: 0.5em 0px 5em auto;
    padding: 1em 1.5em;
    background-color: var(--wheel_btn_bg);
    color: var(--wheel_btn_text);
    border: var(--wheel_btn_border);
    border-radius: 4px;
}

.box-wheel > div{
    margin: 1.5em auto;
}


form.form-code{
    margin: 0px;
    padding: 0px;
}
.form-code .input-code{
    margin: 0px auto;
}
.input-code > div{
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr auto auto;
}

.input-code > div > label{
    color: var(--wheel_label_text);

    grid-column:1 / 4;
    text-align: left;
    align-content: end;
}

.input-code > div > input{
    grid-column:1 / 3;
    text-align: left;
    align-content: end;

    background-color: var(--wheel_input_bg);
    color: var(--wheel_input_text);
    border: var(--wheel_input_border);
    border-radius: 5px 0px 0px 5px;
}

.input-code > div > button{
    border: var(--wheel_btn_border);
    border-radius: 0px 5px 5px 0px;
    padding: 0em 1.5rem;

    background-color: var(--wheel_btn_bg);
    color: var(--wheel_btn_text);
    font-weight: bold;
}

.input-code > div > button:active{
     color: var(--light);
}

.table-award{
    min-width: 80vw;
    padding: 0px;
}

.table-award p{
    font-size: 1.2em;
    padding: 0.6rem  ;
    margin: 0px;
    background-color: var(--wheel_list_hbg);
    color: var(--wheel_list_htext);
    font-weight: bold;
}

.table-award ul{
    overflow-y: auto;
    max-height: 80vh;
    font-size: 1.2em;
    padding: 0em ;
    margin: 0px;
    background-color: var(--white);
    list-style: none;
    text-align: center;
}

.table-award ul li{
    padding: 0.4rem 1.2rem;
    background-color: var(--wheel_list_fbg);
    color: var(--wheel_list_ftext);
}

.table-award ul li:nth-child(even){
    background-color: var(--wheel_list_sbg);
    color: var(--wheel_list_stext);
}

.table-award > ul li::first-letter{
    text-transform: uppercase;
}
.res-wheel{
    text-align: center;
    min-width: 250px;
}

.res-wheel_title{
    font-size: 22px;
    font-weight: bold;
    margin: 0.3em;
}

.res-wheel_award{
    padding: 0.5rem 1.5rem;
    font-size: 1.1em;
    margin: 0px;
}

.res-wheel_code span{
    font-weight: bold;
}

.res-wheel-box_btn{
    text-align: center;
    margin: 2em 0em 0.5em;
}

.showCondition{
    display: block;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    margin-top: 1em;
}

.box-conditions{
    min-width: 80vw;
    padding: 0px;
}

.title-conditions{
    font-size: 1.2em;
    padding: 0.6rem  ;
    margin: 0px;
    background-color: var(--wheel_list_hbg);
    color: var(--wheel_list_htext);
    font-weight: bold;
}


