.hero{
    padding: 6em 2em;
    text-align: center;
    background-color: var(--light);
    font-size: 1rem;
}

.big-text{
    font-weight: bold;
    font-size: 3em;
    margin: 0.2em 0em;
}

.text{
    max-width: 600px;
    margin: 0.8em auto;
    margin-bottom: 1.2em;
    font-size: 1em;
}

.hero button{
    margin-top: 1.5em;
}

@media  screen and (max-width: 600px) {
    .hero{
        font-size: 0.8rem;
    }
}


.icon-box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem 0.5em;
    margin: 2rem auto;
    max-width: 900px;
}
.icon{
    text-align: center;
    font-weight: bold;
}
.icon img{
    display: block;
    max-width: 42px;
    max-height: 42px;
    margin: 0px auto;
}




.info-box{
    font-size: 1rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 400px));
    justify-content: center;
    padding: 4em 3em;
    gap: 1em;
}

.info-box p:first-child
{
    font-size: 2em;
    margin: 0px;
    font-weight: bold;
}

.info-box p
{
    font-size: 1em;
}

.event-box{
    justify-self: center;
    align-content: center;
}

.event-box a{
    font-weight: bold;
    text-align: center;
}

@media  screen and (max-width: 600px) {
    .info-box{
        font-size: 0.8rem;
        grid-template-columns: 1fr;
        padding: 0em 1em 2em;
    }

    .event-box{
        justify-self: right;
    }
}