.code-box{
    margin: 1rem auto;
    padding: 0.8rem;
    max-width: 400px;
}

/* .code-box >  p{
    border: 1px solid var(--dark2);
    height: 100%;
    padding: 0.5rem;
    border-radius: 5px 5px 0px 0px;
    color: var(--dark2);
    font-weight: bold;
    text-align: center;
    margin: 1em 0px 0em;
    background-color: var(--light);
    width: 100%;
} */

.code-box > div{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 0.8em 0.5em;
    background-color: var(--light);
    border: 1px solid var(--dark2);
}

.code-box > div p{
    margin: 0px;
    font-weight: bold;
    color: var(--dark2);
    align-content: center;
}

.code-box  > div input{
    border: 1px solid var(--dark2);
    border-radius:0px;
    border-width: 1px;
    padding: 0.4rem 0.5em;
    margin: 0px;
    background-color: var(--white);
    width: 100%;
}

.table-code{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--dark2);
    border-top: 0px;
    border-radius: 0px 0px 5px 5px;
}

.table-code thead{
    background-color: var(--light);

}
.table-code thead th{
    color: var(--dark2);
    padding: 0.6em 0.5em;
    text-align: left;
}

.table-code tbody td{
    padding: 1em 0.5em;
}

.table-code tbody td:nth-child(3){
   text-align: center;
   padding: 0px;
}

.table-code tbody tr:nth-child(even){
    background-color: rgba(0, 0, 0, 0.1);
}

.table-code button{
    font-weight: bold;
    border: 1px solid var(--white);
    border-radius: 3px;
    background-color: var(--alert);
    padding: 0.2em 0.8em;
    color: var(--white);
}

.table-code tbody td{
    text-align: center;
    font-size: 0.8em;
}


.title-popUp {
    min-width: 80vw;
    text-align: center;
    margin-top: 0.4em;
}

.code-popUp {
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    margin: 1rem 0em;
}

.box_btn-popUp{
    justify-self: center;
}
.box_btn-popUp button{
    margin: 0px 1em;
}