.main{
    display: grid;
    grid-template-columns: 64px 1fr;
    min-height: 80vh;
}

.nav-open{
    grid-template-columns: auto 1fr;
}


.nav{
    background-color: var(--light);
    overflow: hidden;
}

.navElement{
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 1em;
    text-decoration: none;

}
.navElement span{
    align-self: center;
    margin-left: 16px;
}
.navElement img{
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.linkActive{
    background-color: var(--light2);
}


.nav-toggle{
    text-align: center;
    margin: 0.5em ;
    border-bottom: 1px solid var(--text);
}

.nav-toggle > button{
    background: none;
    border: none;
}
.nav-toggle > button img{
    width: 24px;
    height: 24px;
    object-fit: contain;
    transform: rotate(180deg);
    transition: transform 0.2s linear;
}

.nav-open .nav-toggle{ text-align: right; }

.nav-open .nav-toggle > button img{ transform: rotate(0deg) }


.box-outlet{
    position: relative;
}

.black{
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top:0px;  bottom: 0px; right: 0px; left: 0px;
    pointer-events: none;

    transition: background-color 0.2s linear;
}

.nav-open .black{
    background-color: rgba(0,0,0,0.5);
    pointer-events: initial;
}

.popuplogout{
    width: 80vw;
    max-width: 400px;
    min-width: 200px;
    text-align: center;
}