:root{
  --text: rgb(52,52,52);
  --white: rgb(247,247,248);
  --light: rgb(206,229,242);
  --light2: rgb(172,203,225);
  --normal: rgb(124,172,179);
  --dark: rgb(99,112,129);
  --dark2: rgb(83,107,120);

  --alert: rgb(185, 32, 32);
}

*, * > *{
  box-sizing: border-box;
  color: var(--text);
  line-height: 1.5em;
}

.bold{ font-weight: bold;}
.c_normal{color: var(--dark2);}
.t_center{text-align: center;}
.t_right{text-align: right;}


.max900{ max-width: 900px;}
.m_center{margin-left: auto; margin-right: auto;}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main{
  min-height: 80vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn{
  display: inline-block;
  background: none;
  border: 1px solid var(--dark);
  color: var(--dark);
  text-decoration: none;
  cursor: pointer;
  padding: 0.8em 2em;
  min-width: 84px
}

.btn:hover{
  background-color: rgba(0,0,0,0.1);
}

.btn_second{
  background: none;
  border: 0px solid var(--normal);
  color: var(--normal);
  text-decoration: none;
  
  padding: 0.5em 1em;
  min-width: 84px
}

.btn:active{
  color: var(--dark2);
  border-color: var(--dark2);
}
.btn:disabled{
  background-color: var(--dark);
  color: var(--light);
}
.btn-box{
  padding: 0.5em 0em;

}

.btn-box_grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  padding: 0.5em 0em;

}
.btn-box_center{
  text-align: center;
}

.form_alert-box{
  text-align: center;
}

.form_alert-box p{
  color: var(--alert);
  font-weight: bold;
  font-size: 0.8rem;
}

