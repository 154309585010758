.box{
    max-width: 350px;
    margin: 1em auto;
    text-align: center;
    padding: 0.9rem 1.5rem;
    border: 1px solid var(--light);
    border-radius: 10px;
}

.title{
    font-size: 1.7rem;
    margin-bottom: 2rem;
}

.btn{
    font-size: 1.1rem;
    width: 100%;
    padding: 1rem 0.5rem;
    margin-top: 2rem;
}

.alert{
    font-size: 0.9rem;
}

