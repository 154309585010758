.input-box{
    max-width: 300px;
    margin: 1rem auto;
}

.input-box input{
    width: 100%;
    border: 1px solid var(--light);
    background-color: var(--light);
    font-size: 1.0rem;
    padding: 0.8em 0.5em;
    border-radius: 5px;
}

.input-box label{
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}

.input-box input:focus{
    background-color: var(--dark);
    outline-color: var(--dark);
    color: white;
}

.input-box:focus-within label{
    color:  var(--dark);
    font-weight: bold;
}

.input-alert{
    color: var(--alert);
    font-weight: bold;
    font-size: 0.8rem;
    margin: 0.5rem auto 1rem;
}