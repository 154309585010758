.form{
    max-width: 500px;
    overflow-y: auto;
    width: 100%;
    padding: 0px 10px;
    padding: 2em 1em;
    margin:0px auto;
    margin-top:3em;
    margin-bottom: 2em;
}


