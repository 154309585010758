.header{
    padding: 1.2em 1em;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    background-color: var(--light);
    font-size: 1rem;

    z-index: 10;
}

.title{
    display: inline-block;
    color: var(--text);
    font-weight: bold;
    font-size: 1.5em;

    text-decoration: none;
}

.title span{
    color: var(--normal);
}

.nav{
    margin-left: auto; 
    width:100%;
    text-align: right;
    align-content: center;
}

.nav a {
    text-decoration: none;
    font-size: 1em;
    margin: 0.8em;
    color: var(--text);
    font-weight: bold;
}

.nav button {
    background: none;
    border: 1px solid var(--normal);
    color: var(--normal);

    font-size: 0.8rem;
    padding: 0.5em 1em;
    min-width:initial
}