.backdrop{
    position:fixed;
    top:0px ; right: 0px; bottom: 0px; left: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 100;
}

.wrap_popup{
    position: fixed;
    top:50%; left:50%;
    transform: translate(-50%, -50%);
    padding: 0.5em;  
    border-radius: 5px; 
    background-color: white; 
    z-index: 101;
}