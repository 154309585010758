.box{
    margin:0px auto !important;
    padding: 1em 0.8em;
    padding-top: 1rem;
}

.btn-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 300px;
    gap: 15px;
    margin: 1.4rem auto 1rem;
    align-items: center;
}

.btn-box a{
    text-align: center;
}